<template>
	<v-container
		:key="`${$route.query.highlightId}`"
		id="SessionDetail"
		fluid
		tag="section">
		<v-row dense>
			<v-col
				cols="12"
				lg="6">
				<KeyValueCard2
					:loading="detailLoading"
					:item="detail"
					:cols="sessionInfoRows"
					:title="'Session Info'"
					color="secondary">
					<template #[`item.start_time.value`]="{ item }">
						{{ item.start_time | Date }}
					</template>
					<template #[`item.end_time.value`]="{ item }">
						{{ item.end_time | Date }}
					</template>
					<template #[`item.session_mongo_id.value`]="{ item }">
						<v-btn
							icon
							x-small
							v-ripple="false"
							class="elevation-0 transparent"
							color="kajot-text">
							<v-icon
								@click.prevent.stop="copySession(item.session_mongo_id)"
								class="mr-1"
								size="15">
								mdi-content-copy
							</v-icon>
						</v-btn>
						<span>{{ item.session_mongo_id }}</span>
					</template>
					<template #[`item.player_mongo_id.value`]="{ item }">
						<router-link
							:to="{
								name: `player-detail`,
								params: {
									player_id: item.player_mongo_id,
									breadcrumbs: {
										title: `Player ${item.player_mongo_id}`,
									},
								},
							}">
							{{ item.player_mongo_id }}
							{{ item.player ? `/ ${item.player}` : `` }}
						</router-link>
					</template>
					<template #[`item.start_balance.value`]="{ item }">
						{{ item.start_balance | Number({ currency: item.currency }) }}
					</template>
					<template #[`item.device.value`]="{ item }">
						<v-icon v-if="item.device === 0">
							mdi-projector-screen-outline
						</v-icon>
						<v-icon v-else-if="item.device === 1">mdi-monitor</v-icon>
						<v-icon v-else-if="item.device === 2">mdi-cellphone</v-icon>
						<v-icon
							v-else-if="item.device === 3"
							large>
							mdi-tablet
						</v-icon>
						<span v-else>unknown</span>
					</template>
				</KeyValueCard2>
			</v-col>

			<v-col
				cols="12"
				lg="6">
				<KeyValueCard2
					:loading="detailLoading"
					:item="detail"
					:cols="sessionBalanceRows"
					:title="'Session Balance'"
					color="secondary">
					<template #[`item.sum_bet.value`]="{ item }">
						{{ item.sum_bet | Number({ currency: item.currency }) }}
					</template>
					<template #[`item.sum_win.value`]="{ item }">
						{{ item.sum_win | Number({ currency: item.currency }) }}
					</template>
					<template #[`item.netto.value`]="{ item }">
						{{ item.netto | Number({ currency: item.currency }) }}
					</template>
					<template #[`item.max_bet.value`]="{ item }">
						{{ item.max_bet | Number({ currency: item.currency }) }}
					</template>
					<template #[`item.min_bet.value`]="{ item }">
						{{ item.min_bet | Number({ currency: item.currency }) }}
					</template>
					<template #[`item.avg_bet.value`]="{ item }">
						{{ item.avg_bet | Number({ currency: item.currency }) }}
					</template>
				</KeyValueCard2>
			</v-col>
		</v-row>
		<v-row dense>
			<v-col>
				<v-card class="v-card--material px-5 py-3">
					<card-heading
						color="secondary"
						title="Rounds List">
						<v-btn
							v-if="
								_usrFlagsSome({
									key: `session.list`,
									val: permissions.CREATE,
								})
							"
							@click="onExportOpen"
							fab
							color="primary"
							x-small>
							<v-icon>mdi-arrow-down-bold</v-icon>
						</v-btn>
					</card-heading>
					<v-card-text>
						<v-data-table
							must-sort
							sort-by="start_time"
							:sort-desc="true"
							:headers="roundHeadersFiltered"
							:items="roundsList"
							:options.sync="options"
							:server-items-length="total_records"
							:loading="loading"
							:item-class="itemClass"
							:footer-props="footerProps"
							class="elevation-1"
							:value="selected"
							item-key="round_id">
							<template #[`item.copy`]="{ item }">
								<v-btn
									@click="(e) => copyLine(e, item)"
									fab
									text
									x-small>
									<v-icon color="kajot-text">mdi-content-copy</v-icon>
								</v-btn>
							</template>
							<template #[`item.flags`]="{ item }">
								<template v-for="flag of item.flags">
									<v-tooltip
										bottom
										v-if="ROUND_FLAGS[flag]"
										:key="flag">
										<template #activator="{ on, attrs }">
											<v-avatar
												size="30"
												v-on="on"
												v-bind="attrs"
												color="primary"
												class="mx-1">
												<span class="font-weight-bold kajot-anti-text--text">
													{{ ROUND_FLAGS[flag].title }}
												</span>
											</v-avatar>
										</template>
										<span>{{ ROUND_FLAGS[flag].tooltip }}</span>
									</v-tooltip>
								</template>
							</template>
							<template #[`item.start_time`]="{ item }">
								{{ item.start_time | Date }}
							</template>
							<template #[`item.bet`]="{ item }">
								{{ item.bet | Number({ currency: detail.currency }) }}
							</template>
							<template #[`item.win`]="{ item }">
								{{ item.win | Number({ currency: detail.currency }) }}
							</template>
							<template #[`item.netto`]="{ item }">
								{{ item.netto | Number({ currency: detail.currency }) }}
							</template>
							<template #[`item.orientation`]="{ item }">
								<v-icon v-if="item.orientation === 1">
									mdi-cellphone mdi-rotate-90
								</v-icon>
								<v-icon v-else>mdi-cellphone</v-icon>
							</template>
							<template #[`item.finished`]="{ item }">
								<v-icon
									color="primary lighten-1"
									v-if="item.finished">
									mdi-check
								</v-icon>
								<v-icon
									v-else
									color="error">
									mdi-close
								</v-icon>
							</template>
							<template #[`item.actions`]="{ item }">
								<!-- <v-chip color="primary" @click="onRowClick(item)"> -->
								<div class="d-flex flex-row justify-center">
									<v-tooltip
										bottom
										color="primary darken-2">
										<template #activator="{ on, attrs }">
											<v-icon
												color="primary"
												size="22"
												v-bind="attrs"
												v-on="on"
												@click="onRowClick(item)">
												mdi-alpha-i-circle
											</v-icon>
										</template>
										<span>Detail</span>
									</v-tooltip>
									<v-tooltip
										bottom
										color="primary darken-2">
										<template #activator="{ on, attrs }">
											<v-icon
												color="primary"
												size="22"
												v-bind="attrs"
												v-on="on"
												@click="linkOutside('history', item)">
												mdi-alpha-d-circle
											</v-icon>
										</template>
										<span>Round detail</span>
									</v-tooltip>
									<v-tooltip
										bottom
										color="primary darken-2">
										<template #activator="{ on, attrs }">
											<v-icon
												color="primary"
												size="22"
												v-bind="attrs"
												v-on="on"
												@click="linkOutside('replay', item)">
												mdi-play-circle
											</v-icon>
										</template>
										<span>Replay Round</span>
									</v-tooltip>
								</div>
							</template>
						</v-data-table>
					</v-card-text>
				</v-card>
			</v-col>
		</v-row>
		<v-snackbar
			style="position: fixed; bottom: 20px; text-align: center"
			app
			color="menu_background"
			transition="slide-y-reverse-transition"
			:timeout="timeout"
			content-class="kajot-text--text"
			v-model="copied">
			<div class="align-center">
				<v-icon color="info">mdi-information</v-icon>
				{{ copiedText }}
			</div>
			<template #action>
				<v-btn
					fab
					text
					x-small
					@click="copied = false">
					<v-icon
						color="kajot-text"
						small>
						mdi-close
					</v-icon>
				</v-btn>
			</template>
		</v-snackbar>

		<FilterSideBar
			:filterButton="false"
			refreshButton
			locked
			@refresh="reloadRounds"
			:width="isMobile ? '' : '400px'"></FilterSideBar>

		<v-dialog
			v-model="dialog"
			class="my-2 fakin-dialog">
			<RoundDetailModal
				:item="selectedItem"
				:device="detail.device"
				:key="selectedItem?.session_mongo_id"
				:currency="detail.currency"
				@openDetail="linkOutside('history', selectedItem)"
				@close="dialog = false"></RoundDetailModal>
		</v-dialog>

		<v-dialog
			@click:outside="exportClose"
			@keydown.esc="exportClose"
			max-width="500px"
			v-model="exportDialog">
			<ExportDialog
				@close="exportClose"
				builder="paginatedObject"
				delimiter=";"
				:data="exportData"
				:filename="`${detail.session_mongo_id}-rounds`"
				ref="exportDialog"></ExportDialog>
		</v-dialog>
	</v-container>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import _ from "lodash";
import KeyValueCard2 from "../../components/shared/keyValueCard2.vue";
import CardHeading from "../../components/shared/CardHeading.vue";
import permissions from "../../mixins/permissions";
import table from "../../mixins/table";
import RoundDetailModal from "../../components/rounds/roundDetailModal.vue";
import FilterSideBar from "../../components/shared/FilterSideBar.vue";
import { ROUND_FLAGS } from "../../constants/constants";
import { isMobile } from "../../constants/helpers";
import ExportDialog from "../../components/shared/ExportDialog.vue";
import { Round } from "../../constants/converters/round";

export default {
	components: {
		KeyValueCard2,
		CardHeading,
		RoundDetailModal,
		FilterSideBar,
		ExportDialog,
	},
	mixins: [permissions, table],
	data() {
		return {
			highlightHappened: false,
			findingRound: false,

			exportDialog: false,
			timeout: 1000,
			copied: false,
			copiedText: "",
			ROUND_FLAGS,
			dialog: false,
			selectedItem: null,
			highlightedId: -1,
			headers: [
				{ text: "", value: "copy", align: "center", sortable: false },
				{ text: "Round ID", value: "round_id", align: "left" },
				{ text: "Start Time", value: "start_time", align: "center" },
				{
					text: "External Round",
					value: "external_round",
					align: "center",
					sortable: false,
					hide: () => this.detail.external === null,
				},
				{
					text: "External Bet",
					value: "external_bet",
					align: "center",
					sortable: false,
				},
				{
					text: "External Win",
					value: "external_win",
					align: "center",
					sortable: false,
				},
				{ text: "Bet", value: "bet", align: "right" },
				{ text: "Win", value: "win", align: "right" },
				{ text: "Netto", value: "netto", align: "right" },
				// { text: "Hand", value: "_hand", align: "center" },
				{
					text: "Orientation",
					value: "orientation",
					align: "center",
					sortable: false,
					hide: () => this.detail._device === "Desktop",
				},
				{
					text: "Finished",
					value: "finished",
					align: "center",
					sortable: false,
				},
				{
					text: "Flags",
					value: "flags",
					align: "center",
					sortable: false,
				},
				{ text: "Actions", value: "actions", align: "center", sortable: false },
			],
			options: {
				itemsPerPage: parseInt(this.$route.query.perPage ?? 10),
			},
			total_records: 0,
			loading: false,
			detailLoading: false,
			sessionInfoRows: [
				{ label: "Session ID", key: "session_mongo_id" },
				{ label: "Game", key: "game_name" },
				{ label: "Player ID", key: "player_mongo_id" },
				{ label: "Device", key: "device" },
				{ label: "Start time", key: "start_time" },
				{ label: "End time", key: "end_time" },
				{ label: "Start balance", key: "start_balance" },
			],
			sessionBalanceRows: [
				{ label: "Sum Bet", key: "sum_bet" },
				{ label: "Sum Win", key: "sum_win" },
				{ label: "Netto", key: "netto" },
				{ label: "Max Bet", key: "max_bet" },
				{ label: "Min Bet", key: "min_bet" },
				{ label: "Avg Bet", key: "avg_bet" },
				{ label: "Rounds", key: "rounds_count" },
			],
		};
	},
	computed: {
		isMobile,
		...mapGetters(["currentCasino", "loganUrl"]),
		...mapGetters("breadcrumbs", {
			lastCrumb: "lastCrumb",
			shortCrumbs: "shortCrumbsFromHistory",
		}),
		...mapGetters("apiCall", {
			accessHeaders: "accessHeaders",
		}),
		...mapGetters("sessions2", {
			detail: "detail",
			roundsList: "roundsList",
			roundsLoading: "roundsLoading",
		}),
		exportData() {
			return {
				headers: [
					["player_mongo_id", "Player Mongo Id"],
					["start_time", "Start Time"],
					["end_time", "End Time"],
					["netto", "Netto"],
					["game_id", "Game Id"],
					["round_id", "Round Id"],
					["internal_round", "Internal Round"],
					["external_round", "External Round"],
					["internal_bet", "Internal Bet"],
					["external_bet", "External Bet"],
					["internal_win", "Internal Win"],
					["external_win", "External Win"],
					["finished", "Finished"],
					["bet", "Bet"],
					["win", "Win"],
					["hand", "Hand"],
					["orientation", "Orientation"],
					["player_name", "Player Name"],
					["game_name", "Game Name"],
					["currency", "Currency"],
					["flags", "Flags"],
				],
				filter: {},
				url: `${this.loganUrl}/api/v1/casinos/${this.currentCasino}/sessions/${this.detail.session_mongo_id}/rounds/filter`,
				token: this.accessHeaders,
			};
		},
		roundHeadersFiltered() {
			return this.headers.filter((el) => !el.hide?.());
		},
		selected() {
			return this.roundsList.filter((el) => el.round_id === this.highlightedId);
		},
	},
	methods: {
		exportClose(e) {
			this.exportDialog = false;
			this.$refs.exportDialog.onClose();
		},
		onExportOpen() {
			this.exportDialog = true;
			setTimeout(() => {
				this.$refs.exportDialog.onExportOpen();
			}, 500);
		},
		async blur() {
			await new Promise((r) => setTimeout(() => r(), 150));
			document.activeElement.blur();
		},
		copyLine(e, item) {
			blur();
			this.timeout = this.timeout === 1000 ? 1001 : 1000;
			this.copied = true;
			this.copiedText = "Round copied to clipboard.";
			const url = `${window.location.hostname}${
				window.location.port ? `:${window.location.port}` : ""
			}${window.location.pathname}?highlightId=${item.round_id}`;
			navigator.clipboard.writeText(url);
		},
		copySession(sessionId) {
			blur();
			this.timeout = this.timeout === 1000 ? 1001 : 1000;
			this.copied = true;
			this.copiedText = "Session copied to clipboard.";
			navigator.clipboard.writeText(sessionId);
		},
		...mapActions({
			setMessage: "notification/setMessage",
		}),
		...mapActions("sessions2", {
			loadDetail: "loadDetail",
			loadRounds: "loadRoundsList",
		}),
		...mapMutations("sessions2", {
			setPagination: "roundsPagination",
		}),
		reloadRounds() {
			if (this.options.page != 1) {
				this.options.page = 1;
			}
			this._loadTable();
		},
		linkOutside(type, item) {
			if (type === "history") {
				window.open(...item._link);
			} else {
				const replayLink = Round.replayLink(
					item,
					this.currentCasino,
					this.detail.device
				);
				window.open(...replayLink);
			}
		},
		onRowClick(e) {
			this.selectedItem = e;
			this.dialog = true;
		},
		async loadList() {
			return await this.loadRounds(this.$route.params.session_id);
		},
	},
	watch: {
		$route: {
			async handler(newVal, oldVal) {
				if (
					newVal.name === oldVal?.name &&
					newVal.query.highlightId !== oldVal?.query?.highlightId
				) {
					this.highlightedId = parseInt(this.$route?.query?.highlightId) ?? -1;
					if (
						_.inRange(
							parseInt(this.$route.query.highlightId),
							1,
							this.total_records
						)
					) {
						const page = Math.floor(
							(this.total_records - parseInt(this.$route.query.highlightId)) /
								this.options.itemsPerPage
						);
						if (
							this.roundsList.some(
								(el) =>
									el.round_id === parseInt(this.$route.query.highlightId) &&
									el.session_mongo_id === newVal.params.session_id
							)
						) {
							this.highlightHappened = true;
						}
						this.options = { ...this.options, page: page + 1 };
						this.findingRound = true;
					}
				}
				if (
					newVal.name === oldVal?.name &&
					newVal.params.session_id !== oldVal.params.session_id
				) {
					this.loadDetail(newVal.params.session_id);
				}
				this.$nextTick(() => {
					try {
						this.$vuetify.goTo(".v-data-table__selected", {
							duration: 2000,
							offset: 200,
						});
					} catch (error) {
						this.roundsLoading;
					}
				});
			},
			deep: true,
			immediate: true,
		},
		loading: {
			async handler(isLoading, wasLoading) {
				await this.$nextTick();

				if (wasLoading && !isLoading) {
					await this.$nextTick();
					this.$nextTick(() => {
						try {
							if (this.highlightHappened) return;
							this.$vuetify.goTo(".v-data-table__selected", {
								duration: 2000,
								offset: 200,
							});
							setTimeout(() => {
								this.selectedItem = this.roundsList.find(
									(el) => el.round_id === this.$route.query.highlightId
								);
								this.dialog = true;
							}, 1500);
							this.highlightHappened = true;
						} catch (error) {
							this.roundsLoading;
						}
					});

					if (this.findingRound) return;
					if (this.highlightHappened) return;
					if (
						_.inRange(
							parseInt(this.$route.query.highlightId),
							1,
							this.total_records
						)
					) {
						const page = Math.floor(
							(this.total_records - parseInt(this.$route.query.highlightId)) /
								this.options.itemsPerPage
						);
						this.options = { ...this.options, page: page + 1 };
						this.findingRound = true;
					}
				}
			},
		},
	},
	async created() {
		this.detailLoading = true;
		await this.loadDetail(this.$route.params.session_id);
		this.highlightedId = parseInt(this.$route?.query?.highlightId) ?? -1;
		this.detailLoading = false;
	},
	async mounted() {
		this.$nextTick(() => {
			try {
				if (this.highlightHappened) return;
				this.$vuetify.goTo(".v-data-table__selected", {
					duration: 2000,
					offset: 200,
				});
				this.highlightHappened = true;
			} catch (error) {
				this.roundsLoading;
			}
		});
	},
	beforeDestroy() {
		this.highlightHappened = false;
		this.findingRound = false;
	},
};
</script>
<style scoped>
@keyframes example {
	from {
		background-color: var(--v-primary-lighten2);
	}
	to {
		background-color: var(--v-primary-lighten4);
	}
}
@keyframes example--dark {
	from {
		background-color: var(--v-primary-darken2);
	}
	to {
		background-color: var(--v-primary-darken4);
	}
}
::v-deep tr.v-data-table__selected {
	animation-name: example;
	animation-duration: 1.5s;
	animation-iteration-count: infinite;
	animation-direction: alternate;
}
::v-deep .theme--dark tr.v-data-table__selected {
	animation-name: example--dark;
}

::v-deep .v-dialog {
	overflow-y: visible !important;
}
</style>
